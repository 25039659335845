import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Theme } from "@material-ui/core";
import React, { useState, ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";
import testimonials from "../../data/dispatchTestimonials.json";

interface NewsletterRowProps {
  invertColors?: boolean;
  totalUsers?: number;
  tagline?: ReactNode;
}

export default function NewsletterRow({
  invertColors = false,
  totalUsers,
  tagline,
}: NewsletterRowProps) {
  const classes = useStyles({ invertColors });
  const [signupSuccessful, setSignupSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [randomTestimonial] = useState(() => {
    const randomIndex = Math.floor(Math.random() * testimonials.length);
    return testimonials[randomIndex];
  });

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault();
    if (!email) {
      setError("Please enter your email address");
      return;
    }
    if (!validEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    try {
      const currentUrl = window.location.href;
      setLoading(true);
      setError(null);
      const formData = {
        email: email,
        referringSite: currentUrl,
      };
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (
        result &&
        result.data &&
        (result.data.status === "validating" || result.data.status === "active")
      ) {
        setTimeout(() => {
          setSignupSuccessful(true);
          setLoading(false);
          setError(null);
        }, 2000);
      } else {
        setError("Something went wrong. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
      setLoading(false);
    }
  };

  function validEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div className={classes.newsletterRoot}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : !signupSuccessful ? (
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.logoContainer}>
              <StaticImage
                src="../../images/newsletter/dispatch-simple-logo.png"
                alt="Dispatch Newsletter"
                className={classes.dispatchLogo}
                placeholder="blurred"
                height={80}
              />
            </div>
            <div className={classes.description}>
              {tagline ? (
                tagline
              ) : (
                <>
                  Join {totalUsers ? totalUsers : "thousands of"} Android
                  developers getting the most entertaining & insightful Jetpack
                  Compose newsletter
                </>
              )}
            </div>
            <div className={classes.bulletPoints}>
              • Curated insights in 5 minutes or less
              {"\n"}• Entertaining analysis of Android happenings
              {"\n"}• Insider tips from top developers
              {"\n"}• Hidden gems you won't find elsewhere
            </div>
            <div className={classes.testimonial}>
              <div className={classes.quoteDecoration}>"</div>
              <div className={classes.testimonialText}>
                "{randomTestimonial.testimonial}"
              </div>
              <div className={classes.testimonialAuthor}>
                — {randomTestimonial.name}
              </div>
              <div className={classes.testimonialRole}>
                {randomTestimonial.username}
              </div>
            </div>
            <div className={classes.formContainer}>
              <div className={classes.inputRow}>
                <TextField
                  id="newsletter-row-email-address"
                  placeholder="Enter your email"
                  variant="outlined"
                  className={classes.emailField}
                  error={Boolean(error)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (error) setError(null);
                  }}
                  InputProps={{
                    className: classes.inputProps,
                  }}
                />
                <Button
                  variant="contained"
                  className={classes.subscribeButton}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Subscribe
                </Button>
              </div>
              {error && <div className={classes.errorMessage}>{error}</div>}
            </div>
            <div className={classes.subText}>
              Join thousands of Android devs who look forward to Dispatch every
              week
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.successMessage}>
          🎉 Thank you for joining us on the journey! Keep an eye out for an
          email from us 🎉
        </div>
      )}
    </div>
  );
}

export const useStyles = makeStyles<Theme, { invertColors: boolean }>(
  (theme: Theme) => ({
    newsletterRoot: {
      background: (props) => (props.invertColors ? "#222" : "#D6EFFE"),
      borderRadius: 8,
      padding: 32,
      marginLeft: 32,
      marginRight: 32,
      marginBottom: 32,
      maxWidth: 800,
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 24,
    },
    dispatchLogo: {
      height: 80,
      width: "auto",
    },
    description: {
      fontFamily: "Nunito Sans",
      fontWeight: 400,
      fontSize: 20,
      color: (props) => (props.invertColors ? "#FFF" : "#222"),
      textAlign: "center",
      marginBottom: 24,
      lineHeight: 1.5,
    },
    bulletPoints: {
      fontFamily: "Nunito Sans",
      fontWeight: 400,
      fontSize: 16,
      color: (props) => (props.invertColors ? "#FFF" : "#222"),
      whiteSpace: "pre-line",
      textAlign: "left",
      marginBottom: 24,
      lineHeight: 1.8,
      maxWidth: 500,
      margin: "0 auto 24px",
      paddingLeft: 16,
    },
    testimonial: {
      background: "#FFFFFF",
      borderRadius: 12,
      padding: "16px",
      marginBottom: 24,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: "12px",
        marginBottom: 16,
      },
    },
    testimonialText: {
      fontFamily: "Inter",
      fontSize: 14,
      lineHeight: 1.5,
      color: "#374151",
      fontStyle: "italic",
      marginBottom: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        marginBottom: 8,
      },
    },
    testimonialAuthor: {
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 600,
      color: "#111827",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    testimonialRole: {
      fontFamily: "Inter",
      fontSize: 12,
      color: "#6B7280",
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
      },
    },
    quoteDecoration: {
      position: "absolute",
      top: 8,
      left: 8,
      fontSize: 32,
      color: "#4636f7",
      fontFamily: "Georgia",
      opacity: 0.1,
      lineHeight: 1,
    },
    formContainer: {
      marginTop: 24,
      marginBottom: 16,
    },
    inputRow: {
      display: "flex",
      justifyContent: "center",
      gap: 16,
      marginBottom: 8,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    emailField: {
      width: "100%",
      maxWidth: 400,
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFF",
      },
    },
    inputProps: {
      color: "#222",
      backgroundColor: "#FFF",
    },
    subscribeButton: {
      backgroundColor: "#4636f7 !important",
      color: "#FFFFFF",
      padding: "12px 24px",
      height: "fit-content",
      "&:hover": {
        backgroundColor: "#3428b5 !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: 400,
      },
    },
    errorMessage: {
      color: theme.palette.error.main,
      fontSize: 14,
      textAlign: "center",
      marginTop: 8,
    },
    subText: {
      fontFamily: "Nunito Sans",
      fontWeight: 400,
      fontSize: 14,
      color: (props) => (props.invertColors ? "#FFF" : "#222"),
      textAlign: "center",
      opacity: 0.8,
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 200,
    },
    successMessage: {
      fontFamily: "Nunito Sans",
      fontWeight: 400,
      fontSize: 20,
      color: (props) => (props.invertColors ? "#FFF" : "#222"),
      textAlign: "center",
      padding: 32,
    },
  })
);
