import React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

interface IntervieweeHeaderProps {
  name: string;
  title: string;
  imagePath: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const IntervieweeHeader: React.FC<IntervieweeHeaderProps> = ({
  name,
  title,
  imagePath,
}) => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start gap-6 my-8 font-inter">
      <div className="w-[160px] h-[160px] md:w-[200px] md:h-[200px] flex-shrink-0">
        <GatsbyImage
          image={imagePath.childImageSharp.gatsbyImageData}
          alt={name}
          className="rounded-xl w-full h-full"
        />
      </div>
      <div className="text-center md:text-left">
        <div className="text-2xl md:text-3xl font-bold m-0">{name}</div>
        <div className="text-gray-600 font-normal mt-2 m-0 text-lg md:text-xl">
          {title}
        </div>
      </div>
    </div>
  );
};

export default IntervieweeHeader;
